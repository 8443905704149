




























































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { GrupoResiduoService, OrdemDestinacaoService, OrdemServicoService, ReceptorService, ResiduoService, SituacaoOrdemServicoResiduoService } from '@/core/services/residuo';
import { PageBase } from '@/core/models/shared';
import { Cliente } from '@/core/models/geral';
import { ClienteService } from '@/core/services/geral';
import { OrdemServico, OrdemServicoResiduo, Receptor, Residuo, SituacaoOrdemServicoResiduo } from '@/core/models/residuo';
import { ArquivoService } from '@/core/services/shared';
import { EnumSituacaoOrdemServico, EnumSituacaoOrdemServicoResiduo } from '@/core/models/residuo/Enumerados';

@Component
export default class ListaMedicao extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: '#OS', value: 'ordemServico.numero' },
    { text: 'O.D.', value: 'ordemDestinacao.numero' },
    { text: 'Embarcacao', value: 'ordemServico.embarcacao.nome' },
    { text: 'Data Coleta', value: 'dataColeta' },
    { text: 'Cliente', value: 'ordemServico.contrato.cliente.nome' },
    { text: 'Resíduo', value: 'residuo.nome' },
    { text: 'Quantidade', value: 'quantidade' },
    { text: 'U. Medida', value: 'residuo.unidadeMedida.sigla' },
    { text: 'Situacao', value: 'situacao.nome' },
    { text: 'Ticket', value: 'arquivo.ticketPesagem' },
    { text: 'MMR / FCDR', value: 'arquivo.fcdr' },
    { text: 'RT', value: 'registroTransporte' },
    { text: 'MR01', value: 'arquivo.mr01' },
    { text: 'CDF01', value: 'arquivo.cdf01' },
    { text: 'Rel.Rec.1', value: 'arquivo.cartaResponsabilidade' },
    { text: 'R.O.1', value: 'arquivo.relatorioObservacao' },
    { text: 'MR02', value: 'arquivo.mr02' },
    { text: 'CDF02', value: 'arquivo.cdf02' },
    { text: 'Rel.Rec.2', value: 'arquivo.relatorioRecebimento2' },
    // { text: 'R.O.2', value: 'arquivo.relatorioObservacao2' },
    { text: 'MR03', value: 'arquivo.mr03' },
    { text: 'CDF03', value: 'arquivo.cdf03' },
    { text: 'Rel.Rec.3', value: 'arquivo.relatorioRecebimento3' },
    // { text: 'R.O.3', value: 'arquivo.relatorioObservacao3' },
    { text: 'MR04', value: 'arquivo.mr04' },
    { text: 'CDF04', value: 'arquivo.cdf04' },
    { text: 'Rel.Rec.4', value: 'arquivo.relatorioRecebimento4' },
    // { text: 'R.O.4', value: 'arquivo.relatorioObservacao4' },
  ];

  service = new OrdemServicoService();
  clienteService = new ClienteService();
  clientes: Cliente[] = [];
  embarcacoes: any[] = [];
  isClienteLoading: boolean = false;
  onSearchCliente: any = null;
  residuoService = new ResiduoService();
  residuos: Residuo[] = [];
  grupoResiduosService = new GrupoResiduoService(); 
  grupoResiduos: any[] = [];
  receptores: Receptor[] = [];
  receptorService = new ReceptorService();
  isReceptorLoading: boolean = false;
  onSearchReceptor: any = null;
  arquivoService = new ArquivoService();
  ordemDestinacaoService = new OrdemDestinacaoService();
  situacoes: any[] = [];  

  dialogCadastroOrdemServico: boolean = false;
  ordemServico = new OrdemServico();

  exportarDialog: boolean = false;
  exportarOpcao: number = 1;

  pcpDialog: boolean = false;
  pcpOpcao: number = 1;
  pcpCompleto: boolean = false;
  exportarTempo: boolean = false;

  sheet: boolean = false;
  filtro: any = {
    clienteId: null,
    numeroOs: null,
    numeroOd: null,
    mr01: null,
    mr02: null,
    mr03: null,
    mr04: null,
    cdf01: null,
    cdf02: null,
    cdf03: null,
    cdf04: null,
    embarcacaoId: null,
    dataInicial: null,
    dataFinal: null,
    tipoId: null,
    situacaoId: null,
    receptorId: null,
    residuoId: null
  }

  arquivo: any  = {
    mr01: null,
    mr01Chip: true,
    cdf01: null,
    cdf01Chip: true,
    cartaResponsabilidade: null,
    cartaResponsabilidadeChip: true,
    ticketPesagem: null,
    ticketPesagemChip: true,
    fcdr: null,
    fcdrChip: true,
    mr02: null,
    mr02Chip: true,
    cdf02: null,
    cdf02Chip: true
  }

  itemResiduoClass(item: any){

    if(item.situacaoId == EnumSituacaoOrdemServicoResiduo.Cancelado)
      return 'itemOSClass';

    return this.itemExcluido(item);
  }

  itemExcluido(item: any){
    return item.excluido ? 'itemExcluido' : '';
  }

  mounted() {
    this.grupoResiduosService.ListarTudo().then(
      res =>{
        this.grupoResiduos = res.data.items;
        this.grupoResiduos.unshift(
          {
            id: null,
            nome: "TODOS"
          }
        )
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      } 
    )
    new SituacaoOrdemServicoResiduoService().ListarTudo().then(
      res => {
        this.situacoes = res.data.items;
        this.situacoes.unshift({
          id: null,
          nome: "TODOS"
        })
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      } 
    )
    this.residuoService.ListarTudo().then(
      res => {
        this.residuos = res.data.items;
      }
    )
    this.app.clienteId != 0 ? this.filtro.clienteId = this.app.clienteId : 0;
  }

  @Watch('filtro.clienteId')
  PegarClienteEmbarcacoes(val: any){
    if(this.filtro.clienteId == null){
      this.embarcacoes = [];
      this.filtro.embarcacaoId = null;
      return;
    };

    this.clienteService.ObterPorId(this.filtro.clienteId, "Embarcacoes").then(
      res =>{
        this.embarcacoes = [];
        this.filtro.embarcacaoId = null;
        this.clienteService.Embarcacoes(this.filtro.clienteId).then(
          res => {
            this.embarcacoes = res.data;
            this.embarcacoes.unshift(
              {
                id: null,
                nome: "TODOS"
              }
            )
          }
        )
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
  }

  @Watch('onSearchCliente')
  SearchCliente (val: string) {

    if (this.filtro.clienteId) return;
    if (this.isClienteLoading) return;
    if (!val) return;

    this.isClienteLoading = true
    this.clienteService.AutoComplete(val).then(
      res => {
        this.clientes = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
    .finally(() => (this.isClienteLoading = false));
  }

  @Watch('onSearchReceptor')
  SearchReceptor (val: string) {

    if (this.filtro.receptorId) return;
    if (this.isReceptorLoading) return;
    if (!val) return;

    this.isReceptorLoading = true
    this.receptorService.AutoComplete(val).then(
      res => {
        this.receptores = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
    .finally(() => (this.isReceptorLoading = false));
  }

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    this.dialogCadastroOrdemServico = false;

    if (isFiltro == true) {
      this.options.page = 1;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;

    this.service.ListarControleResiduos(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  AbrirDialogCadastroOrdemServico(item: OrdemServico){
    new OrdemServicoService().ObterPorId(item.id, "Contrato.Cliente, Residuos.OrdemDestinacao, Porto, Embarcacao, Base, Motorista, Veiculo.Tipo, Locacoes.Equipamento.Grupo, Locacoes.Parent.Equipamento.Grupo, Locacoes.EquipamentoTroca.Grupo, Locacoes.Tipo, Residuos.Unidade, Residuos.Residuo,  Residuos.Situacao, Servicos.Servico, Situacao, Transportadora, Residuos.Arquivo, Fotos,Residuos.Residuo.Tecnologia").then(
      res => {
        this.ordemServico = new OrdemServico(res.data);
        this.dialogCadastroOrdemServico = true;
      }
    )
  }

  Download(item: OrdemServicoResiduo, tipo: string) {
    this.service.Download(item.ordemServicoId, item.id, tipo).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        switch (tipo) {
          case 'FCDR':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.fcdr!.nome);
            break;
          case 'MR01':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr01!.nome);
            break;
          case 'CDF01':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf01!.nome);
            break;
          case 'CartaResponsabilidade':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cartaResponsabilidade!.nome);
            break;
          case 'TicketPesagem':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.ticketPesagem!.nome);
            break;
          case 'RelatorioObservacao':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioObservacao!.nome);
            break;
          case 'MR02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr02!.nome);
            break;
          case 'CDF02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf02!.nome);
            break;
          case 'RelatorioRecebimento2':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento2!.nome);
            break;
          case 'MR03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr03!.nome);
            break;
          case 'CDF03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf03!.nome);
            break;
          case 'RelatorioRecebimento3':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento3!.nome);
            break;
          case 'MR04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr04!.nome);
            break;
          case 'CDF04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf04!.nome);
            break;
          case 'RelatorioRecebimento4':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento4!.nome);
            break;
          default:
            console.warn(`Não existe esse tipo: ${tipo}.`);
        }

      },
      (err) => this.$swal("Aviso", err.message, "error")
    );
  }

  DownloadODArquivo(item: OrdemServicoResiduo, tipo: string) {
    this.ordemDestinacaoService.Download(item.ordemDestinacao.id, item.ordemServicoId, tipo).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        switch (tipo) {
          case 'MR02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.mr02!.nome);
            break;
          case 'CDF02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.cdf02!.nome);
            break;
          case 'RelatorioRecebimento2':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.relatorioRecebimento2!.nome);
            break;
          case 'MR03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.mr03!.nome);
            break;
          case 'CDF03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.cdf03!.nome);
            break;
          case 'RelatorioRecebimento3':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.relatorioRecebimento3!.nome);
            break;
          case 'MR04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.mr04!.nome);
            break;
          case 'CDF04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.cdf04!.nome);
            break;
          case 'RelatorioRecebimento4':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.relatorioRecebimento4!.nome);
            break;
          default:
            console.warn(`Não existe esse tipo: ${tipo}.`);
        }

      },
      (err) => this.$swal("Aviso", err.message, "error")
    );
  }

  AbrirJanela(id: number, rota: string) {
    let routeLink = this.$router.resolve({ name: rota, params: { id: id.toString() } });
    window.open(routeLink.href, '_blank');
  }

  Imprimir(){
    let routeLink = this.$router.resolve({name: "relatorioMedicao",
      query:{
        numero: this.filtro.numero,
        clienteId: this.filtro.clienteId,
        dataInicial: this.filtro.dataInicial,
        dataFinal: this.filtro.dataFinal,
      }
    });

    window.open(routeLink.href, '_blank');
  }

  GerarPCP(){

    if(this.filtro.clienteId == null || this.filtro.dataInicial == null || this.filtro.dataFinal == null){
      this.$swal("Aviso", "É necessário filtrar Cliente e determinar um período de tempo para executar essa ação", "warning");
    }
    else{
      this.exportarTempo = true;
      this.service.GerarPCP(-1, -1, [], [], this.search, this.headers, this.filtro, this.pcpOpcao, this.pcpCompleto).then(
        res => {
          let file = new Blob([res.data], {type: 'application/zip'});
          let fileURL = URL.createObjectURL(file);
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.download = `PCP.zip`;
          fileLink.click();
        },
        err => {
            if (err.response.status == 400) {
              this.$swal("Aviso", err.response.data, "warning");
            } 
            else {
              this.$swal("Aviso", err.response.data, "error");
            }
          }
      ).finally(() => {
        this.exportarTempo = false;
      })
    }
  }

  GerarExcel(){
    if(this.exportarOpcao == 1){
      this.ExportarExcel('tabela');
    }
    else{
      this.exportarTempo = true;
      this.service.ExportarExcel(-1, -1, [], [], "", this.headers, this.filtro).then(
        async res => {
          const arquivoService = new ArquivoService();
          await arquivoService.Download(res.data, res.headers['content-type'], "Excel.xlsx");
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data, "warning");
          } 
          else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      ).finally(() => {
        this.exportarTempo = false;
      })
    }
  }
}
